import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../../SupportingFiles/colours";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function Disclaimer() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}

      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="pageHeading">Disclaimer</h2>
        </Grid>
        <Grid xs={12}>
          <p className="para">
            The information contained on this website is only for general
            information about Ahalia Insurance Brokers Private Limited (referred
            to as 'Ahalia Insurance' or 'the Company') and the services that it
            offers and is not in any way binding on the Company. It does not
            constitute advice and should not be relied upon in making or
            refraining from making any decision. Ahalia Insurance intends to
            provide clear information about insurance products and services. The
            information and data are generic in nature. Our efforts are to offer
            accurate and responsible data. Ahalia Insurance is not responsible
            for any sort of discrepancies or lacunae in such information. Ahalia
            Insurance hereby excludes any warranty (either express or implied)
            as to the correctness, completeness, timeliness, accuracy, fitness
            for a particular purpose of the Web Site or any of its contents.
          </p>
          <p className="para">
            By accessing this website you agree that Ahalia Insurance will not
            be liable for any direct or indirect or consequential loss, damages
            or compensation arising from the use of the information and material
            contained in this website or from your access of other material on
            the internet via web links for this Web Site.
          </p>
          <p className="para">
            There is no intention of violating any copyright or intellectual
            property rights. All information provided on the Site is subject to
            our discretion of the same and is likely to change without any
            notice. Though, any changes in public utility will be communicated
            immediately in our portal. Unless otherwise stated, copyright or
            similar rights in all material contained in this website is owned by
            Ahalia Insurance. Your access to the site does not imply a free
            license to use it unless specifically permitted by the company.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            DISCLAIMER - Social Networking
          </h5>
          <p className="para">
            Ahalia Insurance Social Media Space on Facebook, Twitter, YouTube,
            Instagram, Flickr and LinkedIn has been created to provide our
            clients, partners, vendors, followers with an opportunity for
            interaction related to Ahalia Insurance and its services. All
            content, comments, videos, visuals, images and any other material
            posted/tagged by users on this site does not necessarily reflect the
            opinions or ideals of Ahalia Insurance, its management, its
            employees or its affiliates.
          </p>
          <p className="para">
            Ahalia Insurance does not endorse any statements, external product
            claim made on this site (if applicable) nor warrants the accuracy of
            any information thereof and is not responsible for any user
            generated content put up on these spaces/websites.
          </p>
          <p className="para">
            All users must comply with the Terms of Use of Facebook, Twitter,
            YouTube, Instagram, Flickr, Foursquare, Pinterest and LinkedIn for
            the Ahalia Insurance's Facebook, Twitter, YouTube, Instagram,
            Flickr, Foursquare, Pinterest and LinkedIn page(s) and account(s).
          </p>
          <p className="para">
            Ahalia Insurance expects that all users refrain from posting any
            content/materials that may fall into any of the following
            categories:
          </p>
          <ul className="listView">
            <li>Abusive, Defamatory or Obscene</li>
            <li>Fraudulent, ambiguous or misleading </li>
            <li>In Violation of Intellectual Property of another </li>
            <li>In Violation of Facebook/Twitter Terms of Service. </li>
            <li>
              In Violation of any law or regulation (including rights of privacy
              and publicity)
            </li>
            <li>Offensive in any other way</li>
          </ul>
          <p className="para mb-10">
            Ahalia Insurance reserves exclusive right and privilege to remove
            any such material/content that may fall under either/all of the
            aforementioned categories. Only that content which is relevant to
            the topics discussed on this page will be allowed to stand.
          </p>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Disclaimer;
