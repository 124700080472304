import React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import "../../../CommonSCSS/CMS.scss";
import { COLORS } from "../../../SupportingFiles/colours";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function PrivacyPolicy() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}

      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="pageHeading">Privacy Policy</h2>
        </Grid>
        <Grid xs={12}>
          <p className="para">
            Please read the following to learn more about our privacy policy. By
            using our website, you expressly give us your consent to use and
            disclose the information provided by you. If you do not agree to the
            terms, you are requested not to use or access our website.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            What Type ofInformation we collect?
          </h5>
          <p className="para">
            Personal information collected by us may include: Name, postal
            address, phone number, and e-mail address.We may also collect
            information about you but not limited to such as:
          </p>
          <ul className="listView">
            <li>
              If you create an account - your username, password, and other
              demographic information that you provide.
            </li>
            <li>
              If you take a survey or interact with us in various other ways -
              demographics information that you provide and information about
              subjects that may interest you.
            </li>
            <li>
              If you connect your account to another service such as Facebook,
              LinkedIn or Twitter, the other service may send us your
              registration or profile information on that service that you
              authorize. This information enables cross-platform data sharing to
              improve your user experience.
            </li>
          </ul>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Use of your personal information
          </h5>
          <p className="para">
            This policy covers how Ahalia Insurance Brokers Private Limited
            (hereinafter referred as the “AIBPL”), treats personal information
            that AIBPL collects and receives, including information related to
            your past use of any products and services. Personal information is
            information about you that is personally identifiable like your
            name, address, email address, or phone number, aadhar details, PAN
            card, KYC details, etc and that is not otherwise publicly available.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Information collection and use
          </h5>
          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            General
          </h5>
          <p className="para">
            AIBPL collects personal information when you visit/use/register with
            AIBPL, when you use displayed products or services, when you visit
            AIBPL pages or the pages of certain AIBPL partners, affiliates,
            group companies and when you enter promotions or sweepstakes. AIBPL
            may combine information about you that we have with information we
            obtain from business partners or other companies.
          </p>
          <p className="para">
            When you register, we may ask for information such as your name,
            email address, birth date, gender, postal code, occupation,
            industry, and personal interests. For some financial products and
            services, if offered, we might also ask for your address and
            information about your assets.If User transacts on the Site, we may
            collect additional Personal Information about your transactions.
            When you browse/ use/register/sign inAIBPL, you are not anonymous to
            us.
          </p>
          <p className="para">
            AIBPL automatically receives and records information from your
            computer and browser, including your IP address, AIBPL cookie
            information,software and hardware attributes, and the page you
            request.
          </p>
          <p className="para">
            In addition, when you use AIBPL services, you may choose to make
            some of your personal information public. If you post personal
            informationonline that is accessible to the public, AIBPL will not
            be responsible for the use or misuse of such information.
          </p>
          <p className="para">
            AIBPL uses information for the following general purposes: to
            customise the advertising and content you see/view fulfil your
            requests forproducts and services, improve our services, contact
            you, conduct research, and provide anonymous reporting for internal
            and external clients.AIBPL is not responsible for the accuracy,
            completeness, veracity nor reliability of any content, products or
            services which may be accessed AIBPL will not be liable for any loss
            or damage arising out of the same.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Eligibility criteria
          </h5>
          <p className="para">
            AIBPL does not contact individuals under age 18 about special offers
            or for marketing purposes without an adult's permission. The usage
            of its services are not available to persons under the age of 18 or
            to anyone previously barred/suspended/ removed from using AIBPL. If
            you are under the age of eighteen (18) you are prohibited to use
            without a parental sponsor and guidance or in any event use the
            Services and in accordance with Applicable laws.
          </p>
          <p className="para">
            By accepting these Terms or by otherwise using the Services or the
            Site, You represent that You are at least 18 years of age and has
            not been previously suspended/barred/removed from using AIBPL.
            Further you represent and warrant that you have the right,
            authority, capacity and competent to enter into this Agreement and
            to abide by all of the terms and conditions of the same. You shall
            not impersonate any person or entity, or falsely state or otherwise
            misrepresent identity, age or affiliation with any person or entity.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Sharing your personal information
          </h5>
          <p className="para">
            AIBPL does not rent, sell, or share personal information about you
            with other people or non-affiliated companies except to provide
            products or services you've requested, when we have your permission,
            or under the following circumstances:
          </p>
          <ul className="listView">
            <li>
              We provide the information to trusted partners who work on behalf
              of or with AIBPL under confidentiality agreements. These companies
              may use your personal information to help AIBPL communicate with
              you about offers from AIBPL and our marketing partners. However,
              these companies do not have any independent right to share this
              information.
            </li>
            <li>
              We may share personal information with our other corporate
              entities and affiliates to help us operate our business and the
              Site or administer activities such as detection of identity theft,
              fraud and other potentially illegal acts, to facilitate joint or
              co-branded services that you request where such services are
              provided by more than one corporate entity. We may share your
              information with these third parties for those limited purposes
              provided that you have given us your permission.
            </li>
            <li>
              We may disclose personal information if required to do so by law
              or in the good faith belief that such disclosure is reasonably
              necessary to respond to subpoenas, court orders, or other legal
              process.
            </li>
          </ul>
          <p>
            We may on occasion provide magazine subscribers' postal mailing
            addresses to selected advertisers who wish to reach you by mail.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Cookies
          </h5>
          <p className="para">
            A “cookie” is a small piece of information stored by a web server on
            a web browser so it can be later read back from that browser.
            Cookies are useful for enabling the browser to remember information
            specific to a given user.
          </p>
          <p className="para">
            We place both permanent and temporary cookies in your computer’s
            hard drive/Mobile. User may choose to set their web browser to
            refuse cookies, or to alert you when cookies are being sent. AIBPL
            lets other companies that show advertisements on some of our pages
            set and access their cookies on your computer. Other companies’ use
            of their cookies is subject to their own privacy policies, not this
            one. Advertisers or other companies do not have access to AIBPL ‘s
            cookies.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Confidentiality
          </h5>
          <p className="para">
            AIBPL limits access to personal information about you to employees
            who we believe reasonably need to come into contact with that
            information to provide products or services to you or in order to do
            their jobs. AIBPL have physical, electronic, and procedural
            safeguards that comply with applicable laws/ regulations/Federal
            regulations to protect personal information about you.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Protection of your information
          </h5>
          <p className="para">
            AIBPL adopts appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your Personal
            Information and data stored on our Site. However, you should be
            aware that no server and/or location is 100% secure and it is
            possible that such may be entered without permission by a computer
            hacker or by someone similarly engaged in unauthorized access. AIBPL
            is not liable if such an act occurs.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Your consent
          </h5>
          <p className="para">
            By using the Website and/ or by providing your information, you
            consent to the collection and use of the information you disclose on
            the Website in accordance with this Privacy Policy, including but
            not limited to your consent for sharing your information as per this
            privacy policy.
          </p>
          <p className="para">
            If we decide to change our privacy policy, we will post those
            changes on this page so that you are always aware of what
            information we collect, how we use it, and under what circumstances
            we disclose it. We encourage and advise you to frequently check for
            any change in these terms.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Grievance Redressal
          </h5>
          <p className="para">
            Ahalia Insurance Brokers Private Limited, is concerned about the
            complaint/grievance of the users and/or clients.
          </p>
          <p className="para">
            This grievance redressal aims at minimizing the instances of
            customer complaints and grievances through proper service delivery
            and review mechanism to ensure prompt redressal of customer
            complaints and grievances. The review mechanism is aimed at helping
            in identification of shortcomings in service delivery, if any.
          </p>
          <p className="para">
            To ensure that the company's grievance redressal mechanism is more
            meaningful and effective, a system/ process has been designed. Such
            system would ensure that the redressal provided is just and fair and
            is permissible within the given framework of the rules and
            regulations. This grievance redressal would be made available at all
            regional offices/branch offices of the company.
          </p>
          <p className="para">
            The concerned employees in the respective branch offices shall be
            made aware about the complaints/ grievance handling process.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            Process
          </h5>
          <p className="para">
            The process followed for addressing queries shall be:
          </p>

          <ul className="listView">
            <li>
              For any grievance, please contact us with the details by sending
              an email to the: <br />
              Principal Officer,{" "}
              <span style={{ fontWeight: "600" }}>
                {" "}
                Mr. Noby M. Thomas on ‘po@ahaliainsurance.com’
              </span>
              <br />
              or write to:
              <br />
              <span style={{ fontWeight: "600" }}>
                Ahalia Insurance Brokers Private Limited, 66/598,
                <br />
                First Floor, Beejay Towers, Rajaji Road, Kochi, Kerala
                <br /> - 682 035 or
              </span>
              <br />
              reach us on:
              <br />
              <span style={{ fontWeight: "600" }}>0484 401 9797.</span>
            </li>
            <li>
              {" "}
              The grievance will be acknowledged within 2 working days of its
              receipt.
            </li>
            <li>
              {" "}
              AIBPL shall exercise all efforts to address it, including
              intimation to the respective Insurance company for a quick
              redressal.
            </li>
          </ul>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default PrivacyPolicy;
