import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./Footer.scss";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box className="Footer">
      <Grid container columnSpacing={3} className="row">
        <Grid xs={12} textAlign={"center"} marginBottom={"40px"}>
          <ul className="topbar-strip">
            <li className="ahalia-box">
              <img src="./images/logo-ahilia.jpg" alt="" />
              <Box>
                <p className="powered_p">
                  Powered by <span>Ahalia Insurance Brokers Pvt Ltd.</span>
                </p>
              </Box>
            </li>

            <li>
              <img src="./images/new-cert.svg" alt="" />
            </li>

            <li>
              <Box className="social-icons">
                <a href="" target="_blank">
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.0942 2.0625H3.90674C3.28514 2.0625 2.689 2.30943 2.24946 2.74897C1.80992 3.18851 1.56299 3.78465 1.56299 4.40625L1.56299 21.5938C1.56299 22.2154 1.80992 22.8115 2.24946 23.251C2.689 23.6906 3.28514 23.9375 3.90674 23.9375H10.6084V16.5005H7.53223V13H10.6084V10.332C10.6084 7.29736 12.415 5.62109 15.1821 5.62109C16.5073 5.62109 17.8931 5.85742 17.8931 5.85742V8.83594H16.3662C14.8618 8.83594 14.3926 9.76953 14.3926 10.7271V13H17.751L17.2139 16.5005H14.3926V23.9375H21.0942C21.7158 23.9375 22.312 23.6906 22.7515 23.251C23.1911 22.8115 23.438 22.2154 23.438 21.5938V4.40625C23.438 3.78465 23.1911 3.18851 22.7515 2.74897C22.312 2.30943 21.7158 2.0625 21.0942 2.0625Z"
                      fill=""
                    />
                  </svg>
                </a>

                <a href="" target="_blank">
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.0942 2.0625H3.90674C2.61279 2.0625 1.56299 3.1123 1.56299 4.40625V21.5938C1.56299 22.8877 2.61279 23.9375 3.90674 23.9375H21.0942C22.3882 23.9375 23.438 22.8877 23.438 21.5938V4.40625C23.438 3.1123 22.3882 2.0625 21.0942 2.0625ZM18.7065 9.81641C18.7163 9.95312 18.7163 10.0947 18.7163 10.2314C18.7163 14.4648 15.4937 19.3428 9.60498 19.3428C7.78857 19.3428 6.104 18.8154 4.68799 17.9072C4.94678 17.9365 5.1958 17.9463 5.45947 17.9463C6.9585 17.9463 8.33545 17.4385 9.43408 16.5791C8.02783 16.5498 6.84619 15.627 6.44092 14.3574C6.93408 14.4307 7.37842 14.4307 7.88623 14.2988C6.42139 14.001 5.32275 12.7119 5.32275 11.1543V11.1152C5.74756 11.3545 6.24561 11.501 6.76807 11.5205C6.32882 11.2283 5.96874 10.832 5.71996 10.3668C5.47117 9.90158 5.34142 9.38203 5.34229 8.85449C5.34229 8.25879 5.49854 7.71191 5.77686 7.23828C7.354 9.18164 9.72217 10.4512 12.3784 10.5879C11.9243 8.41504 13.5503 6.65234 15.5034 6.65234C16.4263 6.65234 17.2563 7.03809 17.8423 7.66309C18.5649 7.52637 19.2583 7.25781 19.8735 6.8916C19.6343 7.63379 19.1313 8.25879 18.4673 8.6543C19.1118 8.58594 19.7368 8.40527 20.313 8.15625C19.8784 8.7959 19.3315 9.3623 18.7065 9.81641Z"
                      fill=""
                    />
                  </svg>
                </a>

                <a href="" target="_blank">
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.8755 2.0625H3.12061C2.26123 2.0625 1.56299 2.77051 1.56299 3.63965V22.3604C1.56299 23.2295 2.26123 23.9375 3.12061 23.9375H21.8755C22.7349 23.9375 23.438 23.2295 23.438 22.3604V3.63965C23.438 2.77051 22.7349 2.0625 21.8755 2.0625ZM8.17432 20.8125H4.93213V10.373H8.1792V20.8125H8.17432ZM6.55322 8.94727C5.51318 8.94727 4.67334 8.10254 4.67334 7.06738C4.67334 6.03223 5.51318 5.1875 6.55322 5.1875C7.58838 5.1875 8.43311 6.03223 8.43311 7.06738C8.43311 8.10742 7.59326 8.94727 6.55322 8.94727ZM20.3276 20.8125H17.0854V15.7344C17.0854 14.5234 17.061 12.9658 15.4009 12.9658C13.7114 12.9658 13.4526 14.2842 13.4526 15.6465V20.8125H10.2104V10.373H13.3208V11.7988H13.3647C13.7993 10.9785 14.8589 10.1143 16.436 10.1143C19.7173 10.1143 20.3276 12.2773 20.3276 15.0898V20.8125Z"
                      fill=""
                    />
                  </svg>
                </a>
                <a href="" target="_blank">
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6841 10.3682L15.3325 13.0098L10.6841 15.6514V10.3682ZM23.438 4.40625V21.5938C23.438 22.8877 22.3882 23.9375 21.0942 23.9375H3.90674C2.61279 23.9375 1.56299 22.8877 1.56299 21.5938V4.40625C1.56299 3.1123 2.61279 2.0625 3.90674 2.0625H21.0942C22.3882 2.0625 23.438 3.1123 23.438 4.40625ZM21.3872 13.0146C21.3872 13.0146 21.3872 10.1045 21.0161 8.70801C20.811 7.93652 20.2104 7.33105 19.4438 7.12598C18.062 6.75 12.5005 6.75 12.5005 6.75C12.5005 6.75 6.93896 6.75 5.55713 7.12598C4.79053 7.33105 4.18994 7.93652 3.98486 8.70801C3.61377 10.0996 3.61377 13.0146 3.61377 13.0146C3.61377 13.0146 3.61377 15.9248 3.98486 17.3213C4.18994 18.0928 4.79053 18.6738 5.55713 18.8789C6.93896 19.25 12.5005 19.25 12.5005 19.25C12.5005 19.25 18.062 19.25 19.4438 18.874C20.2104 18.6689 20.811 18.0879 21.0161 17.3164C21.3872 15.9248 21.3872 13.0146 21.3872 13.0146Z"
                      fill=""
                    />
                  </svg>
                </a>
              </Box>
            </li>

            <li className="email_li">
              <p>
                Phone : <a href="tel: +91 90723-66668"> +91 90723-66668</a>
              </p>
              <p>
                E-mail :{" "}
                <a href="mail: info@ahaliainsurance.com">
                  {" "}
                  info@ahaliainsurance.com
                </a>
              </p>
            </li>
          </ul>
        </Grid>

        <Grid xs={12} textAlign={"center"} marginBottom={"10px"}>
          <ul className="list1">
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}>
                Contact
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CAREER)}>Career</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CLAIMS)}>Claims</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.COMPLAINT)}>
                Complaints
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={12} textAlign={"center"} marginBottom={"10px"}>
          <ul className="list2">
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}>
                Health Insurance
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                Car Insurance
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                Bike Insurance
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                Term Insurance
              </Link>
            </li>
            {/* <li>
              <Link>Investment Plans</Link>
            </li>
            <li>
              <Link>Travel Insurance</Link>
            </li> */}
          </ul>
        </Grid>
        <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
          <ul className="list1">
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.DISCLAIMER)}>
                Disclaimer
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}>
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRIVACY_POLICY)}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                onClick={() => navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION)}
              >
                Cancellation & Refund
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.FAQ)}>
                FAQ
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={12} marginBottom={"20px"}>
          <hr />
        </Grid>
        <Grid xs={12} textAlign={"center"} marginBottom={"15px"}>
          <p className="disclaimer_p">
            All the images used on policyfirst have either been purchased or are
            used with permission of the copyright holder. Images from
            policyfirst.com may not be copied or used elsewhere without
            obtaining appropriate permissions from the copyright holders.
          </p>
        </Grid>
      </Grid>
      <Box className="copyright_box">
        <Grid container columnSpacing={3} className="row ">
          <Grid xs={12} textAlign={"center"}>
            <p>
              {`Copyright @ ${new Date().getFullYear()} policyfirst.com All Right Reserved`}
            </p>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
