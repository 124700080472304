import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { AddTermDTO } from "../../DTO/TermDTO/AddTermFormDTO";
import { TERM_URLS } from "../../Routing/URLS/Term/TernURLs";
// import { TERM_URLS } from "../../URLCollection/Term/TernURLs";

const addTermFormData = (
  onSuccess: Function,
  onError: Function,
  dto: AddTermDTO
) => {
  PostAPI.call(
    TERM_URLS.ADD_TERM_FORM,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const TERM_SERVICES = {
  addTermFormData,
};
