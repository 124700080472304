import React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import TextArea from "../../../Component/Common/FieldTypes/TextArea/TextArea";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function Contact() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}
      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="contactHeading">Get in Touch</h2>
        </Grid>
        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <h3>Don't be shy</h3>
          <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "8px" }}>
            Feel free to get in touch with us. We are always open for
            discussions.
          </p>
          <ul
            className="ct_list"
          >
            <li style={{ minWidth: "30%" }}>
              <img src="../images/call-icon.svg" alt="img" height="50px" />
              <h5>Phone:</h5>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "8px" }}>
                +91 90723-66668,<br/> +91 48440-19797
              </p>
            </li>
            <li style={{ minWidth: "30%" }}>
              <img src="../images/mail-icon.svg" alt="img" height="50px" />
              <h5>E-mail:</h5>
              <Link
                style={{
                  color: "rgba(0, 0, 0, 0.56)",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "14px",
                  display: "inline-block",
                  marginTop: "8px",
                }}
                href="mailto:info@ahaliainsurance.com"
              >
                info@ahaliainsurance.com
              </Link>
            </li>
            <li style={{ minWidth: "30%" }}>
              <img src="../images/location-icon.svg" alt="img" height="50px" />
              <h5>Address:</h5>
              <p style={{ color: "rgba(0, 0, 0, 0.56)", marginTop: "8px" }}>
                66/598, First Floor, Beejay Towers, Rajaji Road, Kochi, Kerala -
                682035
              </p>
            </li>
          </ul>
        </Grid>
        <Grid xs={12} md={6} textAlign="center" order={{ xs: 1, md: 2 }}>
          <RKTextField
            class_name="inputField mb-6"
            title={"Full Name"}
            value={"value"}
            attrName={["name"]}
            value_update={() => {}}
            warn_status={false}
            error_message={" Enter full name"}
            max_length={60}
          />
          <RKTextField
            class_name="inputField mb-6"
            title={"Mobile"}
            value={"value"}
            attrName={["enterMobile"]}
            value_update={() => {}}
            warn_status={false}
            error_message={" Enter mobile"}
            max_length={10}
          />
          <RKTextField
            class_name="inputField mb-6"
            title={"Email"}
            value={"value"}
            attrName={["enterEmail"]}
            value_update={() => {}}
            warn_status={false}
            error_message={"Enter Email"}
            max_length={50}
          />
          <SelectDropdown
            class_name="inputField mb-6"
            title={"Interested In"}
            value={""}
            attrName={["interested"]}
            value_update={() => {}}
            warn_status={false}
            error_message={"Select input"}
            data={[]}
          />
          <TextArea
            class_name="inputField mb-6"
            title={"Describe"}
            value={""}
            attrName={["interested"]}
            value_update={() => {}}
            warn_status={false}
            error_message={"Select input"}
          />
          <CustomButton
            text_name="Call Me Back"
            class_name="regularSecondaryBtn mb-10"
            disabled={true}
          />
        </Grid>
      </Grid>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Contact;
