import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function Aboutus() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}

      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="aboutHeading">About us</h2>
        </Grid>
        <Grid xs={12} md={8}>
          <p className="para">
            Ahalia Insurance Brokers Pvt. Ltd. (AIBPL) is built on a solid
            foundation of core values of integrity, passion, teamwork and mutual
            trust. Integrity is at the heart of the way we operate and behave in
            all our business dealings.Ahalia Insurance Brokers Pvt. Ltd. (AIBPL)
            has been formed by professionals with immense experience in BFSI,
            Healthcare and IT.
          </p>
          <p className="para">
            Ahalia Group started in 1984 as a small clinic in the Emirate of Abu
            Dhabi. Dr. V.S. Gopal, Founder Chairman of Ahalia Group aimed at
            providing international standard of healthcare within the reach of
            common man in UAE. Now, the Group has grown into a conglomerate with
            Two multi-specialty hospitals, 20 satellite medical centers and 35
            pharmacies across UAE. Dr. V.S.Gopal was awarded for Distinguished
            Leader In Health Care and Ahalia Medical Group was awarded for Best
            Pharmacy in the 2019 Annual Health Awards, UAE. In Kerala, Ahalia
            Group has business interests in Healthcare, Finance, Education and
            Power Generation. Ahalia Money Exchange and Financial Services has
            close to 60 branches across Kerala. It is registered as an NBFC and
            FFMC with the RBI. In line with the motto of the Group, “Care with
            Humanity”, Ahalia Group decided to venture into insurance broking to
            provide affordable insurance solutions and improve the insurance
            penetration and thus servicing the general population of the
            Country.
          </p>
        </Grid>
        <Grid xs={12} md={4}>
          <img src="../images/aboutus-img.svg" alt="img" width="100%" />
        </Grid>
        <Grid xs={12}>
          <p className="para">
            We always strive our level best to ensure that we are not just
            looking for customer service but, for customer delight. Whether you
            decide to get in touch with us over the phone, via the internet or
            in person, we are dedicated to providing personal service of
            unmatched quality with utmost care and transparency.
          </p>
          <p className="para">
            At AIBPL, we offer a wide spectrum of life and health insurance
            policies, employee benefits, investment and risk management products
            and services to individuals, businesses, and corporates.
          </p>
          <p className="para">
            We have the scale, depth of expertise and experience to identify and
            meet the uniquely complex demands of India's leading companies. We
            are here to help you uncover and evaluate risks to enable you to
            identify the most appropriate insurance solutions tailored to your
            needs.
          </p>
        </Grid>
        <Grid xs={12} md={9}>
          <h3>Why AIBPL!</h3>
          <ul className="aboutList">
            <li>
              Go beyond broking to become an insurance advisor with the highest
              ethical and professional standards.
            </li>
            <li>
              We believe in knowledge based approach that studies and analyses
              the client's current business situation.
            </li>
            <li>
              We identify the specific risk exposure and Offer customised
              solutions with highest quality coverage and service at competitive
              rates.
            </li>
            <li>Help make insurance understandable to clients.</li>
            <li>Provide outstanding claims service</li>
            <li>
              Become a partner who bring in value addition rather than just
              being an insurance broker with transactional relationship.
            </li>
            <li>
              We aim to be a “One Stop” insurance centre with a dynamic,
              energetic and forward-thinking team of trained professionals who
              believe in delivering outstanding customer service and the most
              relevant insurance solutions.
            </li>
          </ul>
        </Grid>
        <Grid
          xs={12}
          md={3}
          textAlign="right"
          sx={{
            "@media (max-width: 899px)": {
              display: "none",
            },
          }}
        >
          <img
            src="../images/blue-dots.svg"
            width="100%"
            style={{ maxWidth: "200px" }}
          />
        </Grid>
        <Grid xs={12} marginBottom="40px">
          <h3>Professional Management</h3>
          <p className="para mt-3" style={{ fontWeight: "700" }}>
            At AIBPL, we conduct business with the highest standards of
            integrity and make decisions that will delight our clients.
          </p>
          <p className="para">
            The management team comprises of accomplished and well-regarded
            experts who are committed to the highest ethical standards in
            maintaining the company's success. They believe in building a
            company with a sterling reputation for doing things the right way-
            every time, all the time.
          </p>
        </Grid>
      </Grid>
     
      {isMobile ?  <MFooter /> :  <Footer />}
    </Box>
  );
}

export default Aboutus;
