import React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import "../../../CommonSCSS/CMS.scss";
import { COLORS } from "../../../SupportingFiles/colours";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";

function TermsAndConditions() {
  const isMobile = useIsMobile();
  return (
    <Box className="mainWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}
      <Grid container spacing={3} className="row">
        <Grid xs={12}>
          <h2 className="pageHeading">Terms & Conditions</h2>
        </Grid>
        <Grid xs={12}>
          <p className="para">
            Thank you for visiting our website at www.PolicyFirst.com. Please
            read these Terms of Use (the "Agreement" or "Terms of Use")
            carefully before using the services offered or information displayed
            by PolicyFirst. This Agreement sets forth the legally binding terms
            and conditions for your use of the website at www.PolicyFirst.com,
            all other sites owned and operated by PolicyFirst that redirect to
            said website and all subdomains (collectively, the
            “PolicyFirstWebsite”), and the services owned and operated by
            PolicyFirst on the PolicyFirst Website. By accessing and browsing
            the Ahalia Insurance Brokers Ltd ('PolicyFirst') website or by using
            and/or downloading any content from the same, you agree and accept
            the Terms of Use as set forth below. "The Company" refers to Ahalia
            Insurance Brokers Ltd, including its affiliates (also referred to as
            “we”, “us”, or “our”). (PolicyFirst, its subsidiaries and affiliates
            shall be collectively referred to as “PolicyFirst Group
            affiliates”). (This Terms of Use shall be referred and read together
            with Privacy Policy and Disclaimer of the PolicyFirst Website.)
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (A) ACCEPTANCE OF THE TERMS
          </h5>
          <p className="para">
            Your use or viewing the PolicyFirst Website is subject to your
            acceptance of all of the terms and conditions contained in these
            Terms of Use, including the Disclaimer and Privacy Policy available
            at PolicyFirst.com/privacy and PolicyFirst.com/disclaimer, and all
            other operating rules, policies, and procedures that may be
            published on the PolicyFirst Website by the Company, which are
            incorporated by reference.
          </p>
          <p className="para">
            These Terms of Use apply to every user of the PolicyFirst Website.
            In addition, some services offered through the PolicyFirst Website
            may be subject to additional terms and conditions adopted by the
            Company. Your use of those services is subject to those additional
            terms and conditions, which are incorporated into these Terms of Use
            by this reference.
          </p>
          <p className="para">
            We reserve the right, at its sole discretion, to modify or replace
            these Terms of Use by posting the updated terms on the PolicyFirst
            Website. It is your responsibility to check the Terms of Use
            periodically for any changes. Your continued use of the PolicyFirst
            Website following the posting of any changes to the Terms of Use
            constitutes automatic acceptance of those changes.
          </p>
          <p className="para">
            We reserve the right to change, suspend, or discontinue the
            PolicyFirst Website (including, but not limited to, the availability
            of any feature, database, services or content) or any part thereof,
            at any time for any reason. We may also impose limits on certain
            features and services or restrict your access to parts or all of the
            services of PolicyFirst Website without notice or liability.
            PolicyFirst Website and its contents are available only to
            individuals who are at least 18 years old (and at least the legal
            age in your jurisdiction). You represent and warrant that if you are
            an individual, you are at least 18 years old and of legal age in
            your jurisdiction to form a binding contract, and that any personal
            information you may submit will be accurate and truthful. We reserve
            the right to ask for proof of age from you and your account may be
            suspended until satisfactory proof of age is provided. We may, in
            its sole discretion, refuse to offer the PolicyFirst Website to any
            person or entity and change its eligibility criteria at any time.
            This provision is void where prohibited by law and the right to
            access the PolicyFirst Website is revoked in those jurisdictions.
          </p>
          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (B) INTELLECTUAL PROPERTY NOTICE
          </h5>
          <ul className="listType">
            <li>
              The PolicyFirst Website is the exclusive property of PolicyFirst.
              All the contents, documents, information and database included on
              the PolicyFirst Website, including without limitation to such as
              text, graphics, images, logos, button icons, images, audio clips,
              video clips, digital downloads, data compilations, source code,
              reprographics, demos, patches, other files and software), is
              owned, controlled or licensed by or to PolicyFirst and/or Ahalia
              Insurance Brokers’ Group Affiliates and may be protected as
              copyright, database rights, trade mark, intellectual property law
              and other proprietary rights.
            </li>
            <li>
              The marks PolicyFirst, Ahalia, Ahalia Insurance Brokers, indicated
              on each page are the trademarks owned by or licensed to
              PolicyFirst, which also includes any all other graphics, logos,
              page headers, taglines, button icons, scripts and service names.
              The said PolicyFirst trademarks may not be used in connection with
              any product or service that is not in any manner connected or
              provided by PolicyFirst, or in any manner that is likely to cause
              confusion among customers, or in any manner that disparages or
              discredits PolicyFirst.
            </li>
            <li>
              All other trademarks not owned by PolicyFirst or Ahalia Insurance
              Brokers’ Group Affiliates that appear on the PolicyFirst Website,
              are the property of their respective owners, who may or may not be
              affiliated with or connected to PolicyFirst and we do not claim
              any right, interest or affiliation with such parties.
            </li>
            <li>
              Nothing on the PolicyFirst Website or your use of any of the
              services shall be construed as conferring any licence or other
              rights under the intellectual property or other proprietary rights
              of PolicyFirst or Ahalia Insurance Brokers’ Group Affiliates, or
              any third party, whether implied or otherwise, save as expressly
              provided.
            </li>
            <li>
              Any software, including codes or other materials that are made
              available to download from the PolicyFirst Website, is the
              copyrighted work of PolicyFirst and/or its suppliers and
              affiliates. If you download software from the PolicyFirst Website,
              use of the software is subject to the license terms in the
              software license agreement that accompanies or is provided with
              the software. You may not download or install the software until
              you have read and accepted the terms of the applicable software
              license agreement. Without limiting the foregoing, copying or
              reproduction of the software to any other server or location for
              further reproduction or redistribution is expressly prohibited
              unless otherwise provided for in the applicable software license
              agreement in the case of software, or the express written consent
              of PolicyFirst in the case of codes or other downloadable
              materials.
            </li>
          </ul>
          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (C) USER'S COMMENTS AND INFORMATION COLLECTED
          </h5>
          <p className="para">
            The Company does not assume any obligation to monitor the
            information that you may post on its website.
          </p>
          <p className="para">
            You warrant that any and all information, projects, files or other
            attachments sent to us ('Material') or comments other than personal
            data, that you may transmit to the Company through the website does
            not infringe intellectual property rights or any other applicable
            law. Such Material or comments, will be treated as non-confidential
            and non- proprietary. By submitting any information or Material, you
            give the Company an unlimited and irrevocable license to use,
            execute, show, modify and transmit such Material or comments,
            including any underlying idea, concept or know-how. The Company
            reserves the right to use such Material and/or comments in any way
            it chooses.
          </p>
          <p className="para">
            We collect information from a variety of sources, directly and
            indirectly [to specify the sources]. The term “Personal Data” means
            information relating to an identified or identifiable individual
            (i.e. a natural person). All of the information we collect about you
            is used to monitor the use of the site and develop world-class
            products and services of superior quality. Such use does not result
            in any personally identifiable information being collected or
            stored. By submitting your Personal Data through this website, you
            shall be consenting to it being handled in accordance with the
            site's online Privacy Policy. You agree to provide true and complete
            information about yourself by registering on this site and you are
            solely responsible for protecting your password and all acts by way
            of use of your password. If you have submitted Personal Data through
            this website and wish us to cease using it for the purposes
            submitted, please contact us.
          </p>
          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (D) USER RULES OF CONDUCT
          </h5>
          <p className="para">
            By using this PolicyFirst Website, you represent that you have read,
            accepted and are bound by the conditions contained in this
            Agreement. To assist the smooth functioning of this PolicyFirst
            Website, we expect users of the Website to follow these few basic
            guidelines (which form part of the terms and conditions for access
            and use of the PolicyFirst Website) as are acceptable for a user
            policy:
          </p>
          <ul className="listType">
            <li>
              Unless with our prior permission, no part of the PolicyFirst
              Website may be reproduced or transmitted to or stored in any other
              website, nor any of its pages or part thereof be disseminated in
              any electronic or non-electronic form, nor included in any public
              or private electronic retrieval system or service.
            </li>
            <li>
              You have a limited permission to display, print or download
              extracts from these pages of the PolicyFirst Website for your
              personal, non-commercial and non-profit use only and you shall not
              be entitled to commercialise any such material or contents on the
              PolicyFirst Website in any way. Any other use or modification of
              the content of the Company’s web site without the Company’s prior
              written authorisation is prohibited. Framing this site or using
              our proprietary marks as meta tags by you or any person acting on
              your behalf, without our written consent is impermissible. Use of
              contact information provided on the site for unauthorized
              purposes, including marketing is prohibited. Any use intended to
              impede with the working of the site or to intercept any
              information from the site is prohibited.
            </li>
            <li>
              You may not (whether directly or indirectly including through the
              use of any program) create a database in an electronic or other
              form by downloading and storing all or any part of the pages from
              the PolicyFirst Website without our prior written consent save as
              expressly authorised by an agreement in writing between us,
              provided any copies of the pages of the PolicyFirst Website which
              you save to disk or to any other storage system or medium may only
              be used for subsequent viewing purposes or to print extracts for
              personal non-commercial and non-profit use.
            </li>
            <li>
              You may not amend, republish, distribute, reproduce, adapt or
              modify any of the materials on the PolicyFirst Website or use any
              of the materials for public performance or otherwise make
              commercial use of the PolicyFirst Website or any materials located
              on it without our prior written consent.
            </li>
            <li>
              You cannot upload on the PolicyFirst Website or submit any content
              or otherwise distribute or publish through the PolicyFirst Website
              any matter or material which is or may be considered abusive,
              pornographic, illegal, defamatory, obscene, racist, threatening,
              harassing, deceptive, fraudulent, tortious; or which is otherwise
              unlawful, offensive, profane, or invasive of another's privacy or
              designed to cause disruption to any computer systems or network.
              We shall be entitled without liability to the user and at our
              discretion to remove any such content from our server immediately.
            </li>
            <li>
              You shall not directly or indirectly: <strong>(i)</strong>
              decipher, decompile, disassemble, reverse engineer, or otherwise
              attempt to derive any source code or underlying ideas or
              algorithms of any part of the PolicyFirst Website, except to the
              extent applicable laws specifically prohibit such restriction;{" "}
              <strong> (ii)</strong> modify, translate, or otherwise create
              derivative works of any part of the PolicyFirst Website; or{" "}
              <strong> (iii)</strong> copy, rent, lease, distribute, or
              otherwise transfer any of the rights that you receive hereunder.
              You shall abide by all applicable local, state, national, and
              international laws and regulations.
            </li>
            <li>
              You shall not, and shall not permit any third party using your
              account to, take any action, or submit any content, that:
            </li>
            <ul className="alphaList">
              <li>
                infringes any patent, trademark, trade secret, copyright, right
                of publicity, or other right of any other person or entity, or
                violates any law or contract;
              </li>
              <li>you know is false, misleading, or inaccurate;</li>
              <li>
                constitutes unsolicited or unauthorized advertising or
                promotional material or any junk mail, spam, or chain letters;
              </li>
              <li>
                contains software viruses or any other computer codes, files, or
                programs that are designed or intended to disrupt, damage,
                limit, or interfere with the proper function of any software,
                hardware, or telecommunications equipment or to damage or obtain
                unauthorized access to any system, data, password, or other
                information of PolicyFirst or any third party;
              </li>
              <li>
                is made in breach of any legal duty owed to a third party, such
                as a contractual duty or a duty of confidence; or
              </li>
              <li>
                impersonates any person or entity, including any employee or
                representative of the Company.
              </li>
            </ul>{" "}
            <li>
              You shall not: (i) take any action that imposes or may impose (as
              determined by PolicyFirst in its sole discretion) an unreasonable
              or disproportionately large load on PolicyFirst’s or its
              third-party providers’ infrastructure; (ii) interfere or attempt
              to interfere with the proper working of PolicyFirst Website or any
              activities conducted on the same; (iii) bypass any measures
              PolicyFirst may use to prevent or restrict access to the
              PolicyFirst Website (or other accounts, computer systems, or
              networks connected to the same); (iv) run mail list, listserv, or
              any form of auto-responder or "spam" on the PolicyFirst Website;
              or (v) use manual or automated software, devices, or other
              processes to "crawl" or "spider" any page of the PolicyFirst
              Website.
            </li>
            <li>
              No user shall post any message to the PolicyFirst Website which is
              in violation of the acceptable use policies in respect of the
              PolicyFirst Website. We reserve the right to delete and remove all
              such postings.
            </li>
            <li>
              PolicyFirst Website cannot under any circumstances be used for
              commercial purposes by any user
            </li>
            <li>
              We reserve and retain the right or ability to remove the
              availability or ability of participants to post comments in the
              event of any breach of these acceptable use provisions.{" "}
            </li>
            <li>
              We will enforce appropriate sanctions against any of the users of
              the PolicyFirstWebsite who are responsible for abuse of the
              PolicyFirst Website. Such sanctions may include but are not
              limited to (a) a formal warning, (b) suspension of access through
              the PolicyFirst Website or machines, (c) suspension of access to
              the PolicyFirstWebsite, (d) termination of any registration of the
              user with PolicyFirst Website or services.
            </li>
          </ul>
          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (E) APPLICABLE LAW - SEVERABILITY
          </h5>
          <p className="para">
            These Terms of Use are governed by the Indian Law. The courts in
            Mumbai have exclusive jurisdiction in relation to any claim or
            action arising out of, or in connection with, the Terms of Use. If
            any provision of these Terms of Use is held by a court to be
            illegal, invalid or unenforceable, such provision shall, as to such
            jurisdiction only, be deemed severable and ineffective to the extent
            of such invalidity or unenforceability and the remaining provisions
            shall remain in full force and effect.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (F) MODIFICATIONS OF THE TERMS OF USE
          </h5>
          <p className="para">
            The Company reserves the right to change the Terms of Use under
            which this web site is offered at any time and without notice. You
            will be automatically bound by these modifications when you use this
            site and should periodically read the Terms of Use.
          </p>
          <p className="para">
            After having read and understood the disclaimer and copyright
            notice, I hereby agree to accept and abide by all the terms and
            conditions as mentioned therein at my sole responsibility.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (G) INDEMNIFICATION
          </h5>
          <p className="para">
            You agree to defend, indemnify, and hold harmless PolicyFirst,
            Ahalia Insurance Brokers' and its affiliates, and each of its and
            its affiliates' employees, contractors, directors, suppliers, and
            representatives from all liabilities, claims, and expenses,
            including reasonable attorneys' fees and other legal costs, that
            arise from or relate to (a) your use or misuse or an unlawful or
            negligent act or omission of, or access to, the PolicyFirst Website
            and content, or otherwise from your user submissions; (b) violation
            of the Terms of Use; or (c) infringement by you, or any third party
            using your account, of any intellectual property or other right of
            any person or entity. We reserve the right to assume the exclusive
            defence and control of any matter otherwise subject to
            indemnification by you, in which event you will assist and cooperate
            with PolicyFirst in asserting any available defences.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (H) INTERNATIONAL
          </h5>
          <p className="para">
            Accessing PolicyFirst Website is prohibited from territories where
            the content is illegal. If you access the Services from other
            locations, you do so at your own initiative and are responsible for
            compliance with local laws.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (I) ELECTRONIC DELIVERY, NOTICE POLICY, AND YOUR CONSENT
          </h5>
          <p className="para">
            By using PolicyFirst Website, you consent to receive from
            PolicyFirst all communications including notices, agreements,
            legally required disclosures, or other information in connection
            with the PolicyFirst Website (collectively, "Contract Notices")
            electronically. PolicyFirstmay provide the electronic Contract
            Notices by posting them on the PolicyFirst Website. If you desire to
            withdraw your consent to receive Contract Notices electronically,
            you must discontinue your use of the PolicyFirst Website.
          </p>

          <h5
            style={{
              color: COLORS.primary,
              marginTop: "12px",
              fontWeight: "600",
            }}
          >
            (J) ENTIRE AGREEMENT
          </h5>
          <p className="para">
            These Terms of Use and other referenced material are the entire
            agreement between you and PolicyFirst with respect to the
            PolicyFirst Website and supersede all prior or contemporaneous
            communications and proposals (whether oral, written or electronic)
            between you and PolicyFirst with respect to PolicyFirst Website and
            govern the future relationship.
          </p>
          <p className="para mb-10">
            The Terms of Use are personal to you, and are not assignable,
            transferable, or sub-licensable by you except with our prior written
            consent. We may assign, transfer, or delegate any of its rights and
            obligations hereunder without consent. No agency, partnership, joint
            venture, or employment relationship is created as a result of the
            Terms of Use and neither party has any authority of any kind to bind
            the other in any respect. In any action or proceeding to enforce
            rights under the Terms of Use, the prevailing party will be entitled
            to recover costs and attorneys' fees. All notices under the Terms of
            Use will be in writing and will be deemed to have been duly given
            when received, if personally delivered or sent by certified or
            registered mail, return receipt requested; when receipt is
            electronically confirmed, if transmitted by facsimile or e-mail; or
            the day after it is sent, if sent for next day delivery by
            recognized overnight delivery service.
          </p>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermsAndConditions;
