let nodeDomain, strapiDomain, phpDomain, frontendDomain;

if (process.env.REACT_APP_ENV === "DEV") {
  nodeDomain = "https://devnodeapi.policyfirst.com";
  strapiDomain = "https://devstrapi.policyfirst.com";
  phpDomain = "https://devapi.policyfirst.com";
  frontendDomain = "https://dev2.policyfirst.com";
} else if (process.env.REACT_APP_ENV === "STG") {
  nodeDomain = "https://stgnodeapi.muthootinsurancebrokers.com";
  strapiDomain = "https://stgstrapi.bimastreet.com";
  phpDomain = "https://stgapi.muthootinsurancebrokers.com";
  frontendDomain = "https://stg.muthootinsurancebrokers.com";
} else if (process.env.REACT_APP_ENV === "PROD") {
  nodeDomain = "https://nodeapi.muthootinsurancebrokers.com";
  strapiDomain = "https://strapi.bimastreet.com";
  phpDomain = "https://api.muthootinsurancebrokers.com";
  frontendDomain = "https://muthootinsurancebrokers.com";
} else {
  nodeDomain = "";
  strapiDomain = "";
  phpDomain = "";
}

export const NODE_DOMAIN = nodeDomain;
export const STRAPI_DOMAIN = strapiDomain;
export const PHP_DOMAIN = phpDomain;
export const FRONTEND_DOMAIN = frontendDomain;
