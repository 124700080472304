import HomePage from "../../Pages/Desktop/HomePage/HomePage";
import MHomePage from "../../Pages/Mobile/Home/MHomePage";
import useIsMobile from "../../SupportingFiles/MobileProvider";

function HomeContainer() {
  const isMobile = useIsMobile();

  const navigateForm = (form_name: string) => {
    // switch (form_name) {
    //   case "health":
    //     window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
    //     break;
    //   case "car":
    //     window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
    //     break;
    //   case "tw":
    //     window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
    //     break;
    //   case "term":
    //     window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
    //     break;
    //   default:
    //     break;
    // }
  };

  return isMobile ? (
    <MHomePage navigateForm={navigateForm} />
  ) : (
    <HomePage navigateForm={navigateForm} />
  );
}

export default HomeContainer;
