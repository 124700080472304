import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../../SupportingFiles/colours";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import { useState } from "react";
import TWProductContainer from "../../../Container/TWProductContainer/TWProductContainer";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function BikeProductPage() {
  const isMobile = useIsMobile();
  const [pageStatus, setPageStatus] = useState<number>(1);

  return (
    <Box>
      <Box className="mainWrapper">
        {isMobile ? <MNavbar /> : <Navbar />}
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12}>
            <h2 className="pageHeading">Bike Insurance</h2>
          </Grid>
          <Grid xs={12} md={6} lg={7} order={{ xs: 2, md: 1 }}>
            <p className="para">
              The statistics of road accidents in India is alarming. Even an
              experienced driver is not impervious to the perils of driving. A
              Two Wheeler insurance not only covers personal damages, but also
              the costs arising from damages to third party. For a fixed amount
              of premium, a two wheeler insurance will mitigate all financial
              risks that could arise from operating your vehicle. Your Two
              Wheeler insurance can be either a Comprehensive Package policy
              covering lot of perils as against a Third Party or Liability only
              policy which covers damage caused by your vehicle to a third party
              property or death or injury caused to a third party by your
              vehicle.
            </p>
          </Grid>

          <Grid xs={12} md={6} lg={5} order={{ xs: 1, md: 2 }}>
            <Box
              border="1px solid #FF5100"
              borderRadius="12px"
              padding="12px"
              textAlign="center"
              position={"relative"}
            >
              <h4 style={{ color: COLORS.primary, marginBottom: "20px" }}>
                Best Motor Insurance Plans
              </h4>
              <TWProductContainer />
              
            </Box>

            {/* <Box
              border="1px solid #FF5100"
              borderRadius="12px"
              padding="12px"
              textAlign="center"
              minHeight="300px"
            >
              {pageStatus === 1 ? (
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid xs={12}>
                    <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
                      Best Bike Insurance Plan
                    </h5>
                  </Grid>
                  <Grid xs={12}>
                    <Box maxWidth="220px" margin="8px auto">
                      <RKTextField
                        class_name="inputField vehicleNumberField"
                        title={""}
                        value={""}
                        attrName={"reg_no"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter valid vehicle no."}
                        placeholder={"DL01ABC1234"}
                        validation_type="ALL_CAPS"
                        max_length={11}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={3}>
                <Grid xs={12}>
                  <CustomButton
                    text_name="Continue"
                    class_name="mediumSecondaryBtn"
                  />
                </Grid>
                <Grid xs={12}>
                  <Link>Bought a New Bike</Link>
                </Grid>
              </Grid>
            </Box> */}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12} md={8}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              A Comprehensive Package Policy covers:
            </h5>
            <h6 style={{ color: COLORS.secondary }}>
              1. Own damage to the vehicle caused by:
            </h6>
            <ul className="listView">
              <li>Accident external means</li>
              <li>Burglary, housebreaking or theft</li>
              <li>Fire, explosion, self-ignition, lighting</li>
              <li>Terrorism, riots, strike or malicious act</li>
              <li>Transit by road, rail, inland waterway, lift</li>
              <li>Earthquake, flood, storm, landslide, rockslide</li>
              <li>NCB and NCB Protection</li>
              <li>Emergency Assistance</li>
              <li>0% depreciation</li>
            </ul>
            <h6 style={{ color: COLORS.secondary }}>
              2. Personal accident cover:
            </h6>
            <p className="para">
              Which covers the cost of medical treatments of the passengers in
              the vehicle in the event of an accident.
            </p>
            <h6 style={{ color: COLORS.secondary, marginTop: "12px" }}>
              3. Third Party Legal Liability:
            </h6>
            <ul className="listView">
              <li>Accidental death or injury to any third party</li>
              <li>Any damage to property owned by third party</li>
            </ul>
            <h6 style={{ color: COLORS.secondary }}>
              4. Common Add-on Covers available at additional cost:
            </h6>
            <ul className="listView">
              <li>Nil depreciation cover</li>
              <li>Consummables Cover</li>
              <li>Helmet Cover</li>
              <li>Return to Invoice (RTI) cover</li>
            </ul>
          </Grid>
          <Grid xs={12} md={4}>
            <img src="../images/bike-product.svg" width="100%" alt="img" />
          </Grid>
          <Grid xs={12} md={7}>
            <img src="../images/motor-img.svg" alt="img" width="100%" />
          </Grid>
          <Grid xs={12} md={5}>
            <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
              Motor Insurance Policy excludes
            </h5>
            <ul className="listView">
              <li>General aging, wear and tear</li>
              <li>Damage by a person driving without a valid license</li>
              <li>Mechanical or electrical breakdown, failure</li>
              <li>
                Damage by a person driving under the influence of liquor or
                drugs
              </li>
              <li>Depreciation, any consequential loss</li>
              <li>Loss/damage attributable to war, mutiny, nuclear risks</li>
              <li>
                Damage to tyres and tubes, unless damaged during an accident
              </li>
              <li>Loss/damage outside India</li>
              <li>Damage to a vehicle given on rent/hire basis</li>
            </ul>
            <h5 style={{ color: COLORS.primary, marginBottom: "12px" }}>
              Type of Claims Settlement
            </h5>
            <ul className="listView">
              <li>Cashless</li>
              <li>Reimbursement</li>
            </ul>
          </Grid>
        </Grid>
        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </Box>
  );
}

export default BikeProductPage;
