import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "../../../CommonSCSS/CMS.scss";
import CustomButton from "../../../Component/Common/CustomButton/CustomButton";
// import CustomCheckbox from "../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
// import DatePicker from "../../../Component/Common/FieldTypes/DatePicker/DatePicker";
// import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
// import SelectDropdown from "../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../../SupportingFiles/colours";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import CustomCheckbox from "../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import HealthProductContainer from "../../../Container/HealthProductContainer/HealthProductContainer";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

interface FAQItem {
  panel: string;
  question: string;
  details: React.ReactNode;
}

const faqs: FAQItem[] = [
  {
    panel: "panel1",
    question: "How is TPA different from an insurance company?",
    details: (
      <p className="para">
        TPA is a Third Party Administrator licensed by IRDAI to act as a service
        provider to all insurance companies. They facilitate the smooth
        functioning of health insurance policies by offering their value added
        services including claims management, dispatch of policy documents and
        health cards, post sales services, etc.
      </p>
    ),
  },
  {
    panel: "panel2",
    question:
      "I have just bought a mediclaim policy for my family. My daughter is unwell and needs hospitalization. Can I request for a cashless treatment?",
    details: (
      <p className="para">
        Most mediclaim policies have a compulsory waiting period of 30 days
        (except accidental injuries). If you have already exceeded this waiting
        period, you can very well request for a cashless treatment if you choose
        to admit your daughter in a network hospital of your insurance company.
      </p>
    ),
  },
  {
    panel: "panel3",
    question:
      "Can I claim my expenses before and after the surgery I have just undergone?",
    details: (
      <p className="para">
        Yes, you can. Most mediclaim policies cover expenses of 30 days before
        and 60-120 days after hospitalization. Kindly check the policy wordings
        of your insurance policy to check this clause.
      </p>
    ),
  },
  {
    panel: "panel4",
    question:
      "Are there any limits on number of claims I raise on my mediclaim policy?",
    details: (
      <p className="para">
        Well, no. You can claim as many times as the need arises during the
        policy period up to a maximum of your sum insured amount.
      </p>
    ),
  },
  {
    panel: "panel5",
    question:
      "If I buy a mediclaim policy in Mumbai, can I claim on it in Bangalore?",
    details: (
      <p className="para">
        Yes. Mediclaim policies are valid all over India and you can raise a
        claim for the treatment sought in any registered hospital in India.
      </p>
    ),
  },
  {
    panel: "panel6",
    question: "Will I have to undergo any pre-policy medical checkup?",
    details: (
      <p className="para">
        Honestly, this decision lies entirely with the insurance company. If
        they feel the need to do so, you will have to undergo the pre-issuance
        medical checkup at an authorized diagnostic centre suggested by them as
        per your convenience. Typically, a medical checkup is triggered for
        those above 45 years of age or anyone suffering from any pre-existing
        medical condition to evaluate the depth of risk involved.
      </p>
    ),
  },
  {
    panel: "panel7",
    question: "What are the documents required for mediclaim reimbursement?",
    details: <img src="../images/health-ques-img.svg" width="100%" alt="img" />,
  },
];

function HealthProductPage() {
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <Box className="mainWrapper">
        {isMobile ? <MNavbar /> : <Navbar />}
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12}>
            <h2 className="pageHeading">Health Insurance</h2>
          </Grid>
          <Grid xs={12} md={6} lg={7} order={{ xs: 2, md: 1 }}>
            <p className="para">
              Studies reveal that in spite of growing inflation rates clubbed
              with upsurge in healthcare costs, 70% of Indians are yet to opt
              for any sort of insurance cover protecting them against
              hospitalization expenses. It's shocking to know that in a fast
              developing country like India, people are still being ignorant
              about the need to plan for a suitable arrangement to tackle any
              financial emergencies arising out of an accident or sudden
              illnesses.
            </p>
            <p className="para">
              So, why is it important to invest in a mediclaim policy? The
              answer is simple - to safeguard your own financial well-being.
            </p>
            <p className="para">
              Mediclaim policy is a hospitalisation benefit that is offered by
              both public and private sector general insurance companies in
              India. The mediclaim insurance policy takes care of expenses
              following hospitalisation/domiciliary hospitalisation in case of
              any of the following conditions:
            </p>
            <ul className="numberList">
              <li>In case of sudden illness or surgery</li>
              <li>In case of an accident</li>
              <li>In case of any surgery during the policy tenure</li>
            </ul>
          </Grid>
          <Grid xs={12} md={6} lg={5} order={{ xs: 1, md: 2 }}>
            <Box
              border="1px solid #FF5100"
              borderRadius="12px"
              padding="12px"
              textAlign="center"
              position={"relative"}
            >
              <h4 style={{ color: COLORS.primary, marginBottom: "20px" }}>
                Best Health Insurance Plan
              </h4>
              <Grid container columnSpacing={2} rowSpacing={3}>
                <HealthProductContainer />
              </Grid>
              {/* <Grid container columnSpacing={2} rowSpacing={3}>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    value={"value"}
                    attrName={["name"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={" Enter full name"}
                  />
                </Grid>
                <Grid xs={6} textAlign="left">
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "8px 10px 8px 16px",
                      position: "relative",
                      ".MuiCheckbox-root": {
                        padding: "4px",
                      },
                      ".MuiTypography-root": {
                        marginLeft: "4px",
                      },
                    }}
                  >
                    <CustomCheckbox
                      isChecked={false}
                      label="One Adult"
                      attrName="spouse_status"
                      value_update={() => {}}
                      value={""}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} textAlign="left">
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "8px 10px 8px 16px",
                      position: "relative",
                      ".MuiCheckbox-root": {
                        padding: "4px",
                      },
                      ".MuiTypography-root": {
                        marginLeft: "4px",
                      },
                    }}
                  >
                    <CustomCheckbox
                      isChecked={false}
                      label="Two Adults"
                      attrName="spouse_status"
                      value_update={() => {}}
                      value={""}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} textAlign="left">
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "8px 10px 8px 16px",
                      position: "relative",
                      ".MuiCheckbox-root": {
                        padding: "4px",
                      },
                      ".MuiTypography-root": {
                        marginLeft: "4px",
                      },
                    }}
                  >
                    <CustomCheckbox
                      label="Son"
                      attrName={"daughter_status"}
                      value={""}
                      value_update={() => {}}
                      isChecked={false}
                    />

                    <Box
                      position="absolute"
                      right="8px"
                      top="12px"
                      display="flex"
                      alignItems="center"
                      gap="8px"
                      zIndex="9"
                    >
                      <Box
                        onClick={() => {}}
                        height="16px"
                        width="16px"
                        color={COLORS.primary}
                        textAlign="center"
                        sx={{ cursor: "pointer" }}
                      >
                        <RemoveRoundedIcon
                          sx={{ width: "16px", height: "16px" }}
                        />
                      </Box>
                      <Box
                        height="20px"
                        width="20px"
                        color={COLORS.white}
                        borderRadius="40px"
                        textAlign="center"
                        paddingTop="2px"
                        sx={{
                          backgroundColor: "#FF5100",
                          fontSize: "12px",
                        }}
                      >
                        {2}
                      </Box>
                      <Box
                        onClick={() => {}}
                        height="16px"
                        width="16px"
                        color={COLORS.primary}
                        textAlign="center"
                        sx={{ cursor: "pointer" }}
                      >
                        <AddRoundedIcon
                          sx={{ width: "16px", height: "16px" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={6} textAlign="left">
                  <Box
                    sx={{
                      border: "1px solid #BDBDBD",
                      borderRadius: "8px",
                      padding: "8px 10px 8px 16px",
                      position: "relative",
                      ".MuiCheckbox-root": {
                        padding: "4px",
                      },
                      ".MuiTypography-root": {
                        marginLeft: "4px",
                      },
                    }}
                  >
                    <CustomCheckbox
                      label="Daughter "
                      attrName={"daughter_status"}
                      value={""}
                      value_update={() => {}}
                      isChecked={false}
                    />

                    <Box
                      position="absolute"
                      right="8px"
                      top="12px"
                      display="flex"
                      alignItems="center"
                      gap="8px"
                      zIndex="9"
                    >
                      <Box
                        onClick={() => {}}
                        height="16px"
                        width="16px"
                        color={COLORS.primary}
                        textAlign="center"
                        sx={{ cursor: "pointer" }}
                      >
                        <RemoveRoundedIcon
                          sx={{ width: "16px", height: "16px" }}
                        />
                      </Box>
                      <Box
                        height="20px"
                        width="20px"
                        color={COLORS.white}
                        borderRadius="40px"
                        textAlign="center"
                        paddingTop="2px"
                        sx={{
                          backgroundColor: "#FF5100",
                          fontSize: "12px",
                        }}
                      >
                        {2}
                      </Box>
                      <Box
                        onClick={() => {}}
                        height="16px"
                        width="16px"
                        color={COLORS.primary}
                        textAlign="center"
                        sx={{ cursor: "pointer" }}
                      >
                        <AddRoundedIcon
                          sx={{ width: "16px", height: "16px" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title={"Gender"}
                    value={""}
                    attrName={["gender"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={"Select input"}
                    data={[]}
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"DOB"}
                    value={""}
                    attrName={["dob"]}
                    value_update={() => {}}
                    error_message="Enter DOB"
                    warn_status={false}
                    // date_validation_type="MONTHS"
                    // min_date={1200}
                    // max_date={216}
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Pincode"}
                    value={"value"}
                    attrName={["pincode"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={" Enter full name"}
                    max_length={6}
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={"value"}
                    attrName={["enterMobile"]}
                    value_update={() => {}}
                    warn_status={false}
                    error_message={" Enter mobile"}
                    max_length={10}
                  />
                </Grid>
                <Grid xs={12}>
                  <CustomButton
                    text_name="View Quotes"
                    class_name="mediumSecondaryBtn"
                  />
                </Grid>
              </Grid> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="row mb-10">
          <Grid xs={12} md={8}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Features of Mediclaim Policy
            </h5>
            <ul className="listView">
              <li>
                Can be renewed annually. Some insurers offer renewals every 2 or
                3 years
              </li>
              <li>Can be opted for Individual or Family Floater</li>
              <li>For anyone between the age of 91 days to 65 years</li>
              <li>
                Cover pre-existing medical conditions after a waiting period of
                3-4 years
              </li>
              <li>Sum insured opted can be as much as Rs. 50 lakhs</li>
              <li>Can be ported to different insurer at the time of renewal</li>
            </ul>

            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              How Are the Premiums Determined?
            </h5>
            <p className="para">
              Insurance companies consider a lot of factors while determining
              the premiums for the mediclaim policy. Age, sum insured,
              geographical location, any pre-existing medical condition, number
              of members to be insured, the extent of coverage, etc. are some of
              them. While the guidelines are set by IRDAI, health insurance
              companies take the final decision regarding premium calculation on
              the basis of your needs and requirements. The more comprehensive
              your requirements are, the higher will be the premiums to be paid.
            </p>
          </Grid>
          <Grid xs={12} md={4}>
            <img src="../images/health-product.svg" width="100%" alt="img" />
          </Grid>
          <Grid xs={12}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              What's Not Covered in Mediclaim Policy?
            </h5>
            <p className="para">
              While your mediclaim insurance will cover most expenses related to
              hospitalization and related costs, there are certain exclusions
              that are NOT covered in the policy. The list of what's not covered
              forms an integral part of the policy document and should be
              referred for more exhaustive information. Below are a few of the
              aspects that are not covered in most mediclaim policies.
            </p>
            <ul className="listView">
              <li>
                Non-medical expenses like administrative charges, service
                charges, toiletries, diapers, syringes, etc.
              </li>
              <li>
                Diseases/ailments contracted within a specific time from the
                policy purchase
              </li>
              <li>Any dental treatment except in case of an accident</li>
              <li>Sexually Transmitted Diseases and HIV</li>
              <li>Cosmetic surgery, circumcision or plastic surgery</li>
              <li>Vaccinations</li>
              <li>
                Medical condition resulting from the addiction of alcohol,
                smoking, drug abuse, etc.
              </li>
              <li>
                Health conditions arising out of war, nuclear weapon, etc.
              </li>
              <li>What Should You Consider While Buying Mediclaim Policy?</li>
            </ul>
            <p className="para">
              Investing in mediclaim is a great decision. Consider all the
              factors involved and accordingly choose the right policy. Below
              are some of the aspects you need to keep an eye on before
              purchasing the policy:
            </p>
            <ul className="numberList">
              <li className="mb-2">
                <span>Sum insured (coverage amount):</span> While choosing the
                sum insured or the coverage amount, it's advised to consider
                factors like rising healthcare costs, rate of inflation, etc. to
                choose the right amount. Also, if you are living in one of the
                metro cities, your cost of hospitalization will be higher
                compared to rural areas. Similarly, if you are opting for the
                coverage of your dependants too, you might want to consider
                higher sum insured.
              </li>
              <li className="mb-2">
                <span>Individual or Family Floater:</span> This decision
                entirely depends on you. If you are unmarried and looking to
                cover only yourself then you can opt for the individual policy.
                However, if you choose to cover your entire family including
                your parents then selecting a family floater plan is the ideal
                choice. Family floater plans are also economical compared to
                individual policies bought for each family member due to the
                discounts offered by the insurance companies.
              </li>
              <li className="mb-2">
                <span>Sub-limit on Room Rent:</span> Most mediclaim plans are
                tailor-made with room rent sub-limit. These limits might vary
                depending on the insurance companies. To make it simpler, let's
                explain with an illustration. Mr. Parekh bought a plan that had
                room rent sub-limit as 1% of the sum insured. He had chosen the
                coverage for Rs. 2 Lakh. That means, every time Mr. Kulkarni
                wants to claim hospitalization on his mediclaim, the insurance
                company will compensate his expenses limited to the room rent of
                1% of his sum insured i.e. Rs. 2,000 per day. The entire
                calculation of his claim disbursement will be carried out as per
                his room rent limit even if he opts for a room higher than the
                limit of Rs. 2,000. Hence, it's wise to choose the policy with
                proper room rent limit to avoid any rude shocks during claim
                settlement.
              </li>
              <li className="mb-2">
                <span>Co-payment:</span> Many mediclaim policies are in-built
                with co-payment clause. Co-payment is basically a percentage
                amount that the policy holder needs to bear when the claim is
                raised before the insurance company settles the rest. These
                co-payments can range anywhere between 10% and 30% depending on
                the insurance company. For example: If you have opted for a
                mediclaim with 20% co-payment, whenever you raise a claim,
                you’ll have to bear the first 20% of the total claim amount and
                the rest will be compensated by the insurance company as per the
                terms and conditions of the policy. Some insurance companies
                also offer to cover the risks related to pre-existing diseases
                with higher co-payments.
              </li>
              <li className="mb-2">
                <span>Exclusions:</span> Every mediclaim policy is designed to
                cover your medical risks. However, there are certain exclusions
                that are either not covered or covered after a specific term.
                Conditions arising out of a suicide attempt, HIV infection,
                congenital diseases, addiction to alcohol or drugs, etc. are not
                covered by any policy whereas expenses related to maternity,
                hysterectomy, gall bladder surgeries, kidney stones removal,
                etc. are covered after a specific waiting period. Most insurance
                companies cover the risks related to any pre-existing diseases
                only after 3-4 years. Details of these exclusions are mentioned
                in the policy wordings of the plan and one should definitely go
                through the same before finalizing to buy.
              </li>
              <li className="mb-2">
                <span>Hospital Network:</span> One of the biggest benefits of
                opting for a mediclaim policy is the convenience of cashless
                settlement of hospitalization expenses. Every insurance company
                has a network of hospitals and when you seek hospitalization in
                any of these hospitals, you are entitled for a cashless
                treatment if the hospitalization/treatment is within the scope
                of coverage. This works out to be a great financial relief at
                most crucial times. Hence, check out and be aware of the
                hospitals in your area that is in tie-up with the insurance
                company you wish to buy the plan from.
              </li>
              <li className="mb-2">
                <span>Reputation of the Insurance Company:</span> Last but not
                the least, it's always wise to check the credibility and market
                standing of the insurance company you wish to buy the mediclaim
                from. Apart from this, a little study of their claim settlement
                ratio will also prove to be useful while making the final
                decision.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} lg={5}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              How to Claim on the Mediclaim Policy?
            </h5>
            <p className="para">
              With an advancement in the insurance sector, claiming on your
              mediclaim policy has become quite convenient, hassle free and time
              saving. You can claim via Cashless or Reimbursement.
            </p>
            <p className="para">
              If you wish to opt for Cashless Treatment in a network hospital,
              you must contact the TPA (Third Party Administrator) help desk at
              the time of hospital admission. A claim form along with doctor's
              reports has to be submitted to seek approval for the cashless
              treatment. If the request is approved, the insurance company/TPA
              settles the bills for the expenses directly with the hospital
              without involving the policy holder. This is the most convenient
              method of claiming on your mediclaim policy.
            </p>
            <p className="para">
              If, due to any reasons, you are unable to seek cashless treatment,
              you can opt for Reimbursement. Once the treatment is sought in the
              hospital and the amount settled, the claim form along with the
              discharge summary and all other related prescriptions and doctor’s
              reports need to be sent to the insurance company/TPA. The
              insurance company/TPA will then scrutinize the documents for
              correctness and check against the policy terms before taking the
              final decision on claim settlement. Typically, this process takes
              around 15-25 business days.
            </p>
            <p className="para" style={{ fontWeight: "600" }}>
              *Policy Coverage and Exclusions depends on the plan opted
            </p>
          </Grid>
          <Grid xs={12} lg={7}>
            <img src="../images/health-chart.svg" width="100%" alt="img" />
          </Grid>

          <Grid xs={12}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              FAQ - Mediclaim
            </h5>
            {faqs.map((faq) => (
              <Accordion
                key={faq.panel}
                expanded={expanded === faq.panel}
                onChange={handleChange(faq.panel)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === faq.panel ? (
                      <RemoveCircleIcon />
                    ) : (
                      <AddCircleIcon />
                    )
                  }
                  aria-controls={`${faq.panel}bh-content`}
                  id={`${faq.panel}bh-header`}
                >
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingLeft: "0px" }}>
                  {faq.details}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>

          <Grid xs={12}>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Hospital Cash Insurance
            </h5>
            <p className="para">
              One buys health insurance plans to meet the cost of
              hospitalisation. But life is full of surprises. When the hospital
              bill is handed over to you, you may get the shock of your life if
              the entire cost of hospitalization is not reimbursed. In such an
              event, someone not holding a Daily Hospital Cash (DHC) plan will
              have to pay out of pocket. Let's see how a DHC plan works and
              where does it fit into one's health insurance portfolio.
            </p>

            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Out-of-pocket expenses
            </h5>
            <p className="para">
              There may be several incidental expenses incurred during one's
              hospitalisation that would remain outside the ambit of hospital
              bills. These are certain non-admissible hospital expenses which
              the insurer does not pay up. There are some expenses which are
              generally excluded and are termed as non-admissible expenses in
              hospitalisation policies. One may have to incur certain other
              expenses related to eatables and conveyance of friends and
              relatives during hospitalisation. Further, there could be
              sub-limits of expenses, thus restricting the claim amount in the
              policy.
            </p>

            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              How a Daily Hospital Cash (DHC) plan works
            </h5>
            <p className="para">
              Different insurers may call a DHC plan by different names -
              Hospital Cash, Daily Hospital Cash or Hospital Cash Insurance, but
              all work in the same way. Such plans are different from a
              Mediclaim plan in the way they operate. While the Mediclaim plan
              reimburses the hospital bill, the DHC plan pays one on the basis
              of the number of days of hospitalisation. Irrespective of the
              actual amount of hospital expenses, that would include the cost of
              tests, doctor fees and room rent, amongst others, a DHC plan would
              merely look at the number of days of hospitalisation and pay up as
              per the policy terms and conditions. The payout in case of a DHC
              plan is not linked to the actual hospitalisation expenses. What's
              more, you can make your claim under both the DHC and Mediclaim
              policies simultaneously.
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Coverage
            </h5>
            <p className="para">
              Unlike choosing the sum assured in a Mediclaim policy, in a DHC
              plan one has to choose the amount of daily benefits one wants.
              Most DHC plans have daily benefit amounts of Rs 500 / Rs 1,000 /
              Rs 1,500 / Rs 2,500 or Rs 3,000 to choose from. One can buy the
              plan for self or for the entire family members too. Usually,
              insurers give a discount of 5 percent on the premium when more
              than one family member is covered under the same policy. The
              actual payout under a DHC plan is based on the limit chosen but
              will depend on the nature of hospitalisation too. In case of any
              stay in the ICU, the payout may double while in case of surgeries,
              it may even be 5/20 times of the daily limit.
            </p>
            <p className="para">
              <span style={{ color: COLORS.primary, fontWeight: "600" }}>
                Example:
              </span>{" "}
              Assuming someone holds a Mediclaim policy of Rs 2 lakh and also
              buys a DHC plan with Rs 3,000 as daily benefit. He is admitted for
              4 days and the total hospitalisation bill amounts to Rs 34,000.
              While the hospital bill will get reimbursed through the Mediclaim
              policy, he gets Rs 12,000 (Rs 3,000 for 4 days stay) additionally.
            </p>
            <p className="para">
              Usually, most DHC plans double the amount in case of any stay in
              the ICU, i.e. in that case, Rs 24,000 (Rs 6,000 for 4 days stay)
              is paid. In case of a major surgery, the claim amount may total Rs
              60,000 (if it is 20 times of the daily limit for a major surgery).
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Advantages of DHC
            </h5>
            <p className="para">
              If one is holding both the Mediclaim and DHC plans, using the
              latter for making any claim may help one keep the no-claim bonus
              (NCB) intact in the Mediclaim plan. Say, there's 2-3 days of
              hospitalisation and the hospital bill is not huge. If you use your
              Mediclaim policy to pay for it, NCB will get impacted. In such a
              case, using the DHC plan might not only help you get the hospital
              bills cleared, but also keep your NCB intact.
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Differentiation
            </h5>
            <p className="para">
              The working of DHC plans appears pretty simple but the features
              may vary a lot among different plans. For getting an informed
              decision related to choosing a plan, one also needs to understand
              such differentiation and the restrictions.
            </p>
            <p className="para">
              Number of days coverage: In one policy year, the maximum stay in a
              hospital may be capped at 30/40/60 days. Within this, stay in the
              ICU may be capped at, say, 10 days.
            </p>
            <p className="para">
              Benefits during ICU hospitalisation and major, minor surgeries: In
              most DHC plans, the daily benefit amount chosen is doubled if one
              gets hospitalised in the ICU. Further, in some plans, in case of
              major surgeries directly involving brain, heart, liver or lung,
              the benefit can be 20 times of the DHC chosen while in case of
              minor surgeries, the benefits can be five times.
            </p>
            <p className="para">
              <span style={{ color: COLORS.primary, fontWeight: "600" }}>
                Maximum benefit:
              </span>{" "}
              The aggregate of all benefits payable in any one policy year may
              be capped at 150 times of the DHC benefit opted by the
              policyholder. Within this too, the cap, say, for surgical
              hospitalization could be 90 times of the limit.
            </p>
            <p className="para">
              <span style={{ color: COLORS.primary, fontWeight: "600" }}>
                Others:
              </span>{" "}
              Similar to a Mediclaim policy that requires a minimum of 24 hours
              of hospitalisation, some DHC plans may require a minimum of 48
              hours of hospitalisation Some DHC plans also reimburse hospital
              bills related to accidents.
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Conclusion
            </h5>
            <p className="para">
              Do not consider a DHC plan as a replacement of a Mediclaim policy.
              It should always be used as an add-on to supplement your medical
              insurance needs. The first ring of defence is always your
              Mediclaim (individual or Family Floater kind of plans) policy as
              it is a more comprehensive plan. Post that, buy a critical illness
              plan especially if you are around 40. Thereafter, consider buying
              a DHC plan to cover the incidental out-of-pocket costs incurred
              during hospitalization.
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Critical Illness Policy
            </h5>
            <p className="para">
              Do you need extra insurance for life-threatening ailments when you
              have a comprehensive health plan and, maybe, a group cover from
              your employer? In such a case, why buy another insurance policy?
            </p>
            <p className="para">
              But what if you are diagnosed with a critical ailment that
              requires specialised care while your health plan has a limit on
              doctors' fees and, thus, won't cover the full cost of treatment?
              Or, say, there is a cap on specific expenses such as on medicines,
              intensive care unit or prosthetics and your bill is more than what
              the insurer will pay. In such cases, you'll have no option but to
              pay from your pocket.
            </p>
            <p className="para">
              Acute illness can also mean loss of income, total or partial
              disability and change in lifestyle. The financial burden could be
              far more than what an indemnity health plan, which pays hospital
              bills, would cover. Buying a critical illness plan is the best way
              to get over these shortcomings.
            </p>
            <p className="para">
              While an indemnity policy covers hospitalisation, a critical
              illness plan pays a lump sum on diagnosis of serious ailments
              listed in the policy document.
            </p>
            <p className="para">
              The lump sum that you get can be used for various purposes such as
              to pay for expensive treatments or recuperation aids, make up for
              loss of income due to fall in the ability to earn or pay off
              debts. Both these plans provide benefits in different ways.
            </p>
            <p className="para">
              A critical illness plan is a supplement to your health insurance
              portfolio.
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              Critical Illness Policy
            </h5>
            <p className="para">
              Do you need extra insurance for life-threatening ailments when you
              have a comprehensive health plan and, maybe, a group cover from
              your employer? In such a case, why buy another insurance policy?
            </p>
            <p className="para">
              But what if you are diagnosed with a critical ailment that
              requires specialised care while your health plan has a limit on
              doctors' fees and, thus, won't cover the full cost of treatment?
              Or, say, there is a cap on specific expenses such as on medicines,
              intensive care unit or prosthetics and your bill is more than what
              the insurer will pay. In such cases, you'll have no option but to
              pay from your pocket.
            </p>
            <p className="para">
              Acute illness can also mean loss of income, total or partial
              disability and change in lifestyle. The financial burden could be
              far more than what an indemnity health plan, which pays hospital
              bills, would cover. Buying a critical illness plan is the best way
              to get over these shortcomings.
            </p>
            <p className="para">
              While an indemnity policy covers hospitalisation, a critical
              illness plan pays a lump sum on diagnosis of serious ailments
              listed in the policy document.
            </p>
            <p className="para">
              The lump sum that you get can be used for various purposes such as
              to pay for expensive treatments or recuperation aids, make up for
              loss of income due to fall in the ability to earn or pay off
              debts. Both these plans provide benefits in different ways.
            </p>
            <p className="para">
              A critical illness plan is a supplement to your health insurance
              portfolio.
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              BALANCING ACT
            </h5>
            <p className="para">
              The purpose of a critical illness cover is paying for expensive
              treatments. Plus, it is much cheaper than an indemnity plan. For
              instance, a comprehensive health plan for a 30-year-old with a sum
              insured of Rs 5 lakh costs around Rs 6,000 a year. A critical
              illness policy with the same cover costs Rs 1,500 a year.
            </p>
            <p className="para">
              A comprehensive health plan covers a wide range of risks and is
              therefore significantly expensive than a critical illness plan
              that covers specific situations. A combination of comprehensive
              health insurance and critical illness cover can give a good
              balance between pricing and coverage.
            </p>
            <h5 style={{ color: COLORS.primary, margin: "20px 0px 8px" }}>
              WHAT TO BUY
            </h5>
            <p className="para">
              Critical illness covers are fixed benefit plans. One gets the full
              sum insured irrespective of whether one is hospitalised or not or
              what the treatment expenses are. However, details vary from plan
              to plan. For instance, most plans have a survival period clause
              says the insured must survive for at least 30 days after he or she
              is diagnosed with any critical illness to file the claim. But a
              few plans do not have this clause.
            </p>
            <p className="para">
              The number of critical illnesses covered also varies. The built-in
              coverage also differs from policy to policy. While some insure
              accidental death and partial or total disability due to accidents,
              some don't. One should evaluate and compare a few different plans
              to decide which suits one the best. Consider the list of illnesses
              covered, the cover amount, the claim procedure and the payment
              history of the insurer.
            </p>
            <p className="para">
              Here are some points that must be considered while shopping for a
              critical illness policy.
            </p>
            <ul className="numberList">
              <li className="mb-2">
                <span className="d-block">THE RIGHT SIZE</span> The best way to
                decide how much cover you need is to know what the company is
                charging you for. If you know the benefits offered, it will be
                easier to decide how much protection you need.
                <br />
                Keep in mind factors like treatments costs, recurring costs and
                future financial liabilities in case of income loss. Age and
                medical history are important while deciding the appropriate sum
                insured. The sum insured should be higher for the aged as they
                are more likely to develop chronic ailments.
                <br />
                Also take into account existing covers such as Mediclaim or
                personal accident and disability insurance policy.
              </li>
              <li>
                <span className="d-block">STANDALONE OR RIDER?</span> While a
                critical illness plan can be bought as a standalone policy,
                critical illness riders are typically clubbed with life or
                health insurance plans. The policy terms and conditions under
                both the options are more or less the same. The choice between a
                standalone policy and a rider depends on your requirement.
                <br />
                Generally, a standalone policy offers more flexibility in
                choosing the sum insured and larger covers compared to riders.
                The advantage of a standalone critical illness plan is that it
                is not compulsory to renew your health or life plan if you want
                to keep the critical illness cover. Riders are recommended as
                clubbing covers can facilitate easy management. Moreover,
                critical illness plans are sold by both life and general
                insurance companies. The only major difference is that life
                insurers offer policies with longer tenures.
              </li>
            </ul>
          </Grid>
        </Grid>
        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </Box>
  );
}

export default HealthProductPage;
