import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Collapse, Link } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./../../../Component/Common/FieldTypes/FieldTypes.scss";
import "./../../../index.scss";
import "./MNavbar.scss";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";

const MNavbar = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [openLoginMenu, setOpenLoginMenu] = useState(false);

  const toggleDrawer =
    (anchor: "right", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu(!openMenu);
    };

  return (
    <Box className="navbar_m">
      <Box className="top_strip" textAlign={"center"}>
        <p>AHALIA INSURANCE BROKERS PRIVATE LIMITED</p>
      </Box>
      <Box className="logo-strip">
        <Grid container columnSpacing={3} alignItems={"center"}>
          <Grid xs={7} className="logo-section">
            <Link onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              <img
                src="../images/policyfirst-logo.svg"
                alt="policyFirst-logo"
                className="logo"
                onClick={() => {
                  window.location.replace("/");
                }}
              />
            </Link>
          </Grid>
          <Grid xs={5} textAlign={"right"} alignSelf={"center"}>
            <Link className="menuIcon" onClick={toggleDrawer("right", true)}>
              <img src="./images/menu-btn.svg" alt="menu-icon" />
            </Link>
          </Grid>
        </Grid>
      </Box>

      {/* sidemenu */}
      <SwipeableDrawer
        anchor="right"
        open={openMenu}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <Box className="menuWrapper">
          <Box className="menu-section">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>

              <li>
                <Link
                  className="nav-link"
                  onClick={() => setOpenProductMenu(!openProductMenu)}
                >
                  Products <KeyboardArrowDownIcon />
                </Link>
                <Collapse in={openProductMenu} timeout="auto" unmountOnExit>
                  <Link
                    className="nav-link-inner"
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                    }}
                  >
                    Health Insurance
                  </Link>
                  <Link
                    className="nav-link-inner"
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_CAR);
                    }}
                  >
                    Car Insurance
                  </Link>
                  <Link
                    className="nav-link-inner"
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_TW);
                    }}
                  >
                    Bike Insurance
                  </Link>
                  <Link
                    className="nav-link-inner"
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_TERM);
                    }}
                  >
                    Term Insurance
                  </Link>
                  {/* <Link className="nav-link-inner">Investment Plans</Link>
                  <Link className="nav-link-inner">Travel Insurance</Link> */}
                </Collapse>
              </li>
              <li>
                <Link className="nav-link">Claim</Link>
              </li>
              <li>
                <Link className="nav-link">Login</Link>
              </li>

              {/* <li>
                <Link
                  className="nav-link"
                  onClick={() => setOpenLoginMenu(!openLoginMenu)}
                >
                  Login <KeyboardArrowDownIcon />
                </Link>
                <Collapse in={openLoginMenu} timeout="auto" unmountOnExit>
                  <Link className="nav-link-inner">Corporate Login</Link>
                  <Link className="nav-link-inner">Customer Login</Link>
                </Collapse>
              </li> */}
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default MNavbar;
