let nodeDomain, strapiDomain, phpDomain, frontendDomain;

if (process.env.REACT_APP_ENV === "DEV") {
  nodeDomain = "https://devnodeapi.policyfirst.com";
  strapiDomain = "https://strapi.policyfirst.com";
  phpDomain = "https://devapi.policyfirst.com";
  frontendDomain = "https://dev2.policyfirst.com";
} else if (process.env.REACT_APP_ENV === "STG") {
  nodeDomain = "https://stgnodeapi.policyfirst.com";
  strapiDomain = "https://strapi.policyfirst.com";
  phpDomain = "https://stgapi.policyfirst.com";
  frontendDomain = "https://stg.policyfirst.com";
} else if (process.env.REACT_APP_ENV === "PROD") {
  nodeDomain = "https://nodeapi.policyfirst.com";
  strapiDomain = "https://strapi.policyfirst.com";
  phpDomain = "https://api.policyfirst.com";
  frontendDomain = "https://policyfirst.com";
} else {
  // Default to dev environment
  nodeDomain = "https://devnodeapi.policyfirst.com";
  strapiDomain = "https://strapi.policyfirst.com";
  phpDomain = "https://devapi.policyfirst.com";
}

export const NODE_DOMAIN = nodeDomain;
export const STRAPI_DOMAIN = strapiDomain;
export const PHP_DOMAIN = phpDomain;
export const FRONTEND_DOMAIN = frontendDomain;
